<template>
    <!-- 附加职责的tree -->
    <div>
        <el-tree :check-on-click-node="true" ref="tree" :default-expanded-keys="['doctor_additional_responsibilities','nurse_additional_responsibilities']" :render-content="renderContent" @node-expand="handleExpand" :default-checked-keys="checkKeys" @check='basnodeClick' node-key="id" :data="data" show-checkbox :props="defaultProps">
        </el-tree>
    </div>
</template>


<script>
export default {
    props: ["data", "checkKeys"],
    data() {
        return {
            defaultProps: {
                children: "children",
                label: "name",
            },
        };
    },
    methods: {
        basnodeClick() {
            this.$emit("basnodeClick", this.$refs.tree.getCheckedKeys());
        },
        baschooseAll(val) {
            if (val) {
                //全选
                this.$refs.tree.setCheckedNodes(this.data);
            } else {
                //取消选中
                this.$refs.tree.setCheckedKeys([]);
            }
            this.$emit("basnodeClick", this.$refs.tree.getCheckedKeys());
        },
        renderContent(h, { node, data, store }) {
            // console.log(node.level);
            let classname = "";
            // 第三层节点添加className
            if (node.level === 2) {
                classname = "levelname";
            }
            return <p class={classname}>{node.label}</p>;
        },
        handleExpand() {
            this.$nextTick(() => {
                this.changeCss();
            });
        },
        changeCss() {
            const levelName = document.getElementsByClassName("levelname"); // levelname是上面的最底层节点的名字
            for (let i = 0; i < levelName.length; i++) {
                // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
                levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
                levelName[i].parentNode.style.styleFloat = "left";
                levelName[i].parentNode.onmouseover = function () {
                    this.style.backgroundColor = "#fff";
                };
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #fff;
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #409eff;
}

/deep/ .el-tree-node {
    float: left;
    text-align: center;
    margin-top: 15px;
}
</style>
