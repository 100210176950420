<template>
    <div>
        <el-tree class="stafftree" :check-on-click-node="true" :default-expand-all="true" :check-strictly="true" :expand-on-click-node="false" ref="tree" :default-checked-keys='checkKeys' @check-change='basnodeClick' node-key="id" :data="data" show-checkbox :props="defaultProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">

                <div v-if="targetName=='基本指标'">
                    <el-popover placement="bottom" width="400" trigger="click" v-if="data.indicatorThirdLibraryList">
                        <div>指标内涵：{{data.intension}}</div>
                        <div>指标说明：{{data.explain}}</div>
                        <i class="el-icon-question" slot="reference"></i>
                    </el-popover>
                    <span> {{ node.label }}</span>
                </div>
                <div v-else>
                    <el-popover placement="bottom" width="400" trigger="click" v-if="!data.indicatorThirdLibraryList">
                        <div>指标内涵：{{data.intension}}</div>
                        <div>指标说明：{{data.explain}}</div>
                        <i class="el-icon-question" slot="reference"></i>
                    </el-popover>
                    <span> {{ node.label }}</span>
                </div>

            </span>
        </el-tree>
    </div>
</template>


<script>
// :check-strictly="true" //父子节点不关联
export default {
    props: ["data", "checkKeys", "tit", "targetName"],
    data() {
        return {
            defaultProps: {
                children: "indicatorThirdLibraryList",
                label: "name",
            },
        };
    },
    methods: {
        basnodeClick(data, node) {
            // console.log(data);
            // console.log(node);
            let res = this.$refs.tree.getCheckedNodes();
            let arr = [];
            res.forEach((item) => {
                if (!item.indicatorThirdLibraryList) {
                    arr.push(item.id);
                }
            });
            // console.log(arr);
            this.$emit("basnodeClick", arr);
        },
        baschooseAll(val) {
            if (val) {
                let arr = [];
                for (let i = 0; i < this.data.length; i++) {
                    arr.push(...this.data[i].indicatorThirdLibraryList);
                }
                //全选
                this.$refs.tree.setCheckedNodes(arr);
            } else {
                //取消选中
                this.$refs.tree.setCheckedKeys([]);
            }
            // console.log(this.$refs.tree.getCheckedKeys());
            this.$emit("basnodeClick", this.$refs.tree.getCheckedKeys());
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #fff;
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #409eff;
}

/deep/ .el-tree-node {
    float: left;
    width: 300px;
    text-align: center;
    margin-top: 15px;
}

.el-icon-question {
    color: #918686;
    margin-left: 5px;
}

/deep/ .el-tree {
    .is-leaf + .el-checkbox .el-checkbox__inner {
        display: inline-block;
    }

    .el-checkbox .el-checkbox__inner {
        display: none;
    }
}

/deep/ .stafftree > .el-tree-node > .el-tree-node__content .el-checkbox {
    display: none;
}

/deep/ .stafftree .el-tree-node {
    width: 100%;
    .el-tree-node__children {
        display: flex;
        flex-wrap: wrap;
        .el-tree-node {
            width: 25%;
        }
    }
}
</style>
